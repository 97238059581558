export enum Page {
  NONE = "NONE",
  /* Preconfigured URL Pages */
  NEW_APPLICATION = "NEW_APPLICATION",
  NEW_PURCHASE_APPLICATION = "NEW_PURCHASE_APPLICATION",
  NEW_REFINANCE_APPLICATION = "NEW_REFINANCE_APPLICATION",

  /* Landing Pages */
  HOME = "HOME",
  NEW_LOAN_REDIRECTOR = "NEW_LOAN_REDIRECTOR",
  REFI_REDIRECTOR = "REFI_REDIRECTOR",
  PORTAL_OVERVIEW = "PORTAL_OVERVIEW",
  PORTAL_SETTINGS = "PORTAL_SETTINGS",

  /* Direct specific ('New Loan' Origination) Steps */
  INTENT = "INTENT",
  RESUME_LOAN_FORM = "RESUME_LOAN_FORM",
  START_APPLICATION = "START_APPLICATION",
  SERVICE_UPDATE_FORM = "SERVICE_UPDATE_FORM",
  SIGNUP_FORM = "SIGNUP_FORM",
  EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
  PERSONAL_INFO_FORM = "PERSONAL_INFO_FORM",
  HOUSING_INFO_FORM = "HOUSING_INFO_FORM",
  EMPLOYMENT_INFO_FORM = "EMPLOYMENT_INFO_FORM",
  APPLICATION_SUMMARY = "APPLICATION_SUMMARY",
  SOFT_CREDIT_CONSENT = "SOFT_CREDIT_CONSENT",
  SOCIAL_SECURITY_FORM = "SOCIAL_SECURITY_FORM",
  SOFT_CREDIT_ERROR = "SOFT_CREDIT_ERROR",
  ORDER_INFO = "ORDER_INFO",
  PREAPPROVAL_SUCCESS = "PREAPPROVAL_SUCCESS",
  NO_LOANS = "NO_LOANS",

  /* Shared Origination Steps */
  APPLY = "APPLY",
  APPLY_REDIRECTOR = "APPLY_REDIRECTOR",
  WAITLIST = "WAITLIST",
  MMYT_MAKE = "MMYT_MAKE",
  MMYT_MODEL = "MMYT_MODEL",
  MMYT_TRIM = "MMYT_TRIM",
  PREAPPROVAL_DECLINE = "PREAPPROVAL_DECLINE",
  NEW_VEHICLE_INFO_FORM = "NEW_VEHICLE_INFO_FORM",
  OFFER_TERM_EDIT_FORM = "OFFER_TERM_EDIT_FORM",
  PASSTHROUGH_OFFERS = "PASSTHROUGH_OFFERS",
  OFFER_DEFERRED_PAYMENT_EDIT_FORM = "OFFER_DEFERRED_PAYMENT_EDIT_FORM",
  HARD_CREDIT_FORM = "HARD_CREDIT_FORM",
  CONFIRMATION_FORM = "CONFIRMATION_FORM",
  LOAN_CONTRACT = "LOAN_CONTRACT",
  LOAN_APP_COMPLETE = "LOAN_APP_COMPLETE",
  FINAL_LOAN_SUMMARY = "FINAL_LOAN_SUMMARY",

  // Business-specific steps
  BUSINESS_INFO = "BUSINESS_INFO",
  BUSINESS_OWNERSHIP = "BUSINESS_OWNERSHIP",

  /* Refi specific ('Refinance Origination') Steps */
  REFI_SOFT_CREDIT_CONSENT = "REFI_SOFT_CREDIT_CONSENT",
  REFI_VEHICLE_INFO_FORM = "REFI_VEHICLE_INFO_FORM",
  REFI_VEHICLE_CONFIRMATION = "REFI_VEHICLE_CONFIRMATION",
  REFI_PREVIOUS_LOANS_LIST = "REFI_PREVIOUS_LOANS_LIST",
  REFI_PREVIOUS_LOAN_INFO = "REFI_PREVIOUS_LOAN_INFO",
  REFI_LOAN_OFFERS_FORM = "REFI_LOAN_OFFERS_FORM",
  REFI_FINAL_CONFIRMATION = "REFI_FINAL_CONFIRMATION",
  CONGRATULATIONS = "CONGRATULATIONS",

  /* Lending Tree Claim Steps */
  LENDING_TREE_LANDING = "LENDING_TREE_LANDING",
  LENDING_TREE_SSN = "LENDING_TREE_SSN",
  LENDING_TREE_SIGN_IN = "LENDING_TREE_SIGN_IN",
  LENDING_TREE_SIGN_UP = "LENDING_TREE_SIGN_UP",
  LENDING_TREE_CLAIM_CONFIRMED = "LENDING_TREE_CLAIM_CONFIRMED",

  /* V3 flow pages */
  VEHICLE = "VEHICLE",

  /* Deprecated pages */
  ADDRESS_FORM = "ADDRESS_FORM",
}

export default { Page };
